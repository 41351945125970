import * as React from 'react'
import { Mutation } from 'react-apollo'
import { Button, Input, message } from 'antd'
import gql from 'graphql-tag'

import Section from '../../components/DescriptionList'
import Divider from '../../components/Divider'
import { CampaignDetail } from '../../definitions'

const SEND_CAMPAIGN_ANNOUNCEMENT = gql`
  mutation SendCampaignAnnouncement($input: SendCampaignAnnouncementInput!) {
    sendCampaignAnnouncement(input: $input)
  }
`

type DetailProps = {
  campaign: CampaignDetail
  loading?: boolean
}

type DetailState = {
  content: string
  contentEn: string
  contentZhHans: string
  link: string
  password: string
  loading: boolean
  warning: string | null
  error: any
} & CampaignDetail

class SendCampaignNoticeForm extends React.Component<DetailProps, DetailState> {
  state = {
    ...this.props.campaign,
    content: '',
    contentEn: '',
    contentZhHans: '',
    link: '',
    password: '',
    loading: false,
    warning: null,
    error: null,
  }

  private action = async (sendCampaignAnnouncement: any): Promise<any> => {
    if (!sendCampaignAnnouncement) {
      return
    }

    this.setState((prev) => ({
      ...prev,
      loading: true,
      warning: null,
      error: null,
    }))

    try {
      const {
        id,
        content,
        contentEn,
        contentZhHans,
        link,
        password,
      } = this.state

      await sendCampaignAnnouncement({
        variables: {
          input: {
            campaign: id,
            announcement: [
              {
                language: 'zh_hant',
                text: content,
              },
              {
                language: 'en',
                text: contentEn,
              },
              {
                language: 'zh_hans',
                text: contentZhHans,
              },
            ].filter(({ text }) => !!text),
            link,
            password,
          },
        },
      })

      this.setState(
        (prev) => ({
          ...prev,
          password: '',
          loading: false,
          error: null,
        }),
        () => {
          message.success('發送成功')
        }
      )
    } catch (error) {
      console.error(error)
      this.setState(
        (prev) => ({ ...prev, password: '', loading: false, error }),
        () => {
          message.error('發送失敗')
        }
      )
    }
  }

  public render() {
    const {
      content,
      contentEn,
      contentZhHans,
      link,
      password,
      loading,
    } = this.state

    return (
      <Mutation mutation={SEND_CAMPAIGN_ANNOUNCEMENT}>
        {(sendCampaignAnnouncement: any, { client }: any) => (
          <>
            <Section title="通知內容" col={1}>
              <Section.Description term="繁體">
                <Input
                  defaultValue={content}
                  onChange={(e) => {
                    this.setState({ content: e.target.value })
                  }}
                />
              </Section.Description>
              <Section.Description term="英文">
                <Input
                  defaultValue={contentEn}
                  onChange={(e) => {
                    this.setState({ contentEn: e.target.value })
                  }}
                />
              </Section.Description>
              <Section.Description term="簡體">
                <Input
                  defaultValue={contentZhHans}
                  onChange={(e) => {
                    this.setState({ contentZhHans: e.target.value })
                  }}
                />
              </Section.Description>

              <Section.Description term="連結">
                <Input
                  defaultValue={link}
                  onChange={(e) => {
                    this.setState({ link: e.target.value })
                  }}
                />
              </Section.Description>
            </Section>
            <Divider size="large" />
            <Section title="">
              <Section.Description term="管理員帳號密碼">
                <Input
                  defaultValue={password}
                  value={password}
                  onChange={(e) => {
                    this.setState({ password: e.target.value })
                  }}
                />
              </Section.Description>

              <Section.Description term="">
                <Button
                  type="primary"
                  onClick={() => this.action(sendCampaignAnnouncement)}
                  disabled={loading}
                >
                  確認發送
                </Button>
              </Section.Description>
            </Section>

            <section style={{ color: '#999' }}>
              <p>
                （將發送通知給成功報名「{this.props.campaign.name}」的{' '}
                {this.props.campaign.participants.totalCount} 名參加者）
              </p>
            </section>
          </>
        )}
      </Mutation>
    )
  }
}

export default SendCampaignNoticeForm
