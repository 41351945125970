import * as React from 'react'
import { Skeleton, Empty } from 'antd'

import ErrorMessage from '../../components/ErrorMessage'

import withCampaignDetail, {
  CampaignDetailChildProps,
} from '../CampaignDetail/withCampaignDetail'
import SendCampaignNoticeForm from './SendCampaignNoticeForm'

class CampaignSendNotices extends React.Component<CampaignDetailChildProps> {
  public render() {
    const {
      data: { campaign, loading, error },
    } = this.props

    if (error) {
      return <ErrorMessage error={error} />
    }

    if (loading) {
      return <Skeleton active />
    }

    if (!campaign) {
      return <Empty />
    }

    return (
      <>
        <SendCampaignNoticeForm campaign={campaign} />
      </>
    )
  }
}

export default withCampaignDetail(CampaignSendNotices)
